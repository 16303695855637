var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseBreadcrumb',{attrs:{"title":'Matriz Formato Encuesta'}}),_c('div',{staticStyle:{"padding-top":"55px"}},[_c('v-card',{staticClass:"elevation-4 px-5 py-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.body,"height":430,"dense":"","no-results-text":"No se encontraron datos","no-data-text":"No se encontraron datos","search":_vm.search,"hide-default-footer":true,"disable-pagination":"","fixed-header":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-space-between align-center pa-2"},[_c('div',{staticClass:"black--text pt-1"},[_vm._v("FORMATOS DE ENCUESTA")]),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0 text-field-search-matriz",attrs:{"dense":"","append-icon":"mdi-magnify","label":"Buscar","background-color":"rgb(220, 220, 220, 0.16)","rounded":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","dark":"","x-small":""},on:{"click":_vm.nuevo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo")])])],1),_c('v-divider')]},proxy:true},{key:"item.isGlobal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isGlobal == 0 ? 'Por Empresa' : 'Por Coorporación')+" ")]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start pa-0"},[(item.canEdit == '1')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(item.canEdit == '1')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","icon":"","x-small":"","color":"success"},on:{"click":function($event){return _vm.viewItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Encuesta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","icon":"","color":"blue-grey darken-2"},on:{"click":function($event){return _vm.duplicateItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","icon":"","color":"orange darken-3"},on:{"click":function($event){return _vm.assignQuiz(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-account-details ")])],1)]}}],null,true)},[_c('span',[_vm._v("Asignar Encuesta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.viewReport(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-file-table-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reporte de Respuestas")])])],1)]}}],null,true)})],1)],1),_c('asignacion-encuestas',{attrs:{"dialogTable":_vm.dialog,"survey":_vm.itemSeleccionado},on:{"cerrarDialog":function($event){_vm.dialog = false}}}),_c('ver-formato-encuesta',{attrs:{"dialog":_vm.dialogVer,"survey":_vm.itemSeleccionado},on:{"cerrarDialog":function($event){_vm.dialogVer = false}}}),_c('reporte-respuestas',{attrs:{"dialog":_vm.dialogReporte,"survey":_vm.itemSeleccionado},on:{"cerrarDialog":function($event){_vm.dialogReporte = false}}})],1),(_vm.showLoading)?_c('div',{staticClass:"progress-background"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-dialog v-model="dialog" @click:outside="cerrarDialog" scrollable max-width="1200px">
    <v-card v-if="survey !== null">
      <v-card-title>Ver Formato Encuesta</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <datos-formato-encuesta
          :surveyFormat="surveyFormat"
          :isView="true"
          :itemsCampaigns="itemsCampaigns"
        ></datos-formato-encuesta>
        <seccion-formato-encuesta
          v-for="(section, i) in surveyFormat.sections"
          :key="i"
          :position="i"
          :number="numberInActive(section.id)"
          :total="sectionsActives.length"
          :section="section"
          :typesQuestion="typesQuestion"
          :showFirstSection="surveyFormat.showFirstSection"
          :isView="true"
        >
        </seccion-formato-encuesta>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="cerrarDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import DatosFormatoEncuesta from '@/gquest/encuesta/formato_encuesta/components/DatosFormatoEncuesta';
import SeccionFormatoEncuesta from '@/gquest/encuesta/formato_encuesta/components/SeccionFormatoEncuesta';
import FormatoEncuestaService from '../services/FormatoEncuestaService';
import Swal from 'sweetalert2';

export default {
  name: 'VerFormatoEncuesta',
  components: {
    DatosFormatoEncuesta,
    SeccionFormatoEncuesta
  },
  computed: {
    surveyFormat() {
      return this.$store.state.surveyFormat;
    },
    selection() {
      return this.$store.state.selection;
    },
    sectionsActives() {
      if (this.$store.state.surveyFormat.sections)
        return this.$store.state.surveyFormat.sections.filter((element) => element.status === true);
      else return [];
    }
  },
  props: {
    survey: {
      type: Object,
      default: null
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    typesQuestion: [],
    formatoEncuestaService: null,
    showLoading: false,
    valid: false,
    edit: false,
    mostrarFormato: false,
    isView: null,
    itemsCampaigns: []
  }),
  watch: {
    dialog(val) {
      if (val) this.initialize();
    }
  },
  methods: {
    async getCampaign() {
      const res = await this.formatoEncuestaService.get().execResource('listarCampanas');
      if (res.status) {
        this.itemsCampaigns = res.data;
      }
    },
    numberInActive(id) {
      let number;
      this.sectionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    },
    async initialize() {
      this.showLoading = true;

      await this.getCampaign();
      const resTP = await this.formatoEncuestaService.get().execResource('tiposPregunta');
      if (resTP.status) {
        this.typesQuestion = resTP.data;
      }
      if (this.survey) {
        const res = await this.formatoEncuestaService.get().execResource('obtenerFormatoEncuesta', {
          quiz_id: this.survey.id
        });
        if (res.status) {
          let surveyFormat = res.data[0];
          let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
          surveyFormat = { ...surveyFormat, showFirstSection: showFirstSection };
          this.$store.commit('SET_FORMATO_ENCUESTA', surveyFormat);
          this.$store.commit('RESET_SELECTION_ELEMENT_FORMATO');
          this.mostrarFormato = true;
        } else {
          await this.alertDialog('error', res.data);
          this.$router.push({ name: 'matriz_formato_encuesta' });
        }
      }
      this.showLoading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    atras() {
      this.$router.push({ name: 'matriz_formato_encuesta' });
    },
    typeSelected(id) {
      let type = this.typesQuestion.find((el) => el.id === id);
      return type;
    },
    cerrarDialog() {
      this.$emit('cerrarDialog');
    }
  },
  created() {
    this.formatoEncuestaService = this.$httpService(new FormatoEncuestaService(), this);
    this.initialize();
  }
};
</script>

<template>
  <v-dialog v-model="dialogTable" @click:outside="cerrarDialog" scrollable max-width="900px">
    <v-card v-if="survey !== null">
      <v-card-title>
        <h4>ASIGNAR ENCUESTAS</h4>
        <v-divider class="mx-2" vertical></v-divider>
        Encuesta: {{ survey.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="mr-auto">
            <h3>
              Campaña: {{ survey.campaign }} | Alcance: {{ survey.isGlobal == 0 ? 'Por Empresa' : 'Por Coorporación' }}
            </h3>
          </v-col>
          <v-col cols="12" class="mr-auto">
            <!--v-model selectedAuxSedes, items itemsSedes-->
            <v-autocomplete
              v-model="usuarioSeleccionado"
              :items="usuarios"
              @input="searchInputUsuario = null"
              :search-input.sync="searchInputUsuario"
              label="Usuario"
              dense
              filled
              multiple
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <div v-if="usuarioSeleccionado.length < usuarios.length" class="d-flex">
                  <span v-if="index < 2">{{ item.text }}</span>
                  <span v-if="usuarioSeleccionado.length > 1 && index < 1">,&nbsp;</span>
                  <span v-if="index == 2" class="ml-2 grey--text text-caption">
                    (+{{ `${usuarioSeleccionado.length - 2 > 1 ? 'Otros ' : ''}${usuarioSeleccionado.length - 2}` }})
                  </span>
                </div>
                <span v-else-if="index == 0">
                  TODOS
                </span>
              </template>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="selectAll(usuarioSeleccionado, usuarios, 'U')" @mousedown.prevent>
                  <v-list-item-action>
                    <v-icon color="primary">{{ iconCheckboxAll(usuarioSeleccionado, usuarios) }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> TODOS </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-autocomplete>

            <!--<v-autocomplete
              v-model="usuarioSeleccionado"
              :items="usuarios"
              item-text="text"
              dense
              filled
              return-object
              label="Usuario"
              multiple
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="usuarioSeleccionado.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Seleccionar Todos
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template> </v-autocomplete
            >-->

            <v-data-table
              v-if="usuarioSeleccionado"
              dense
              :headers="headers"
              :items="encuestasAsignadas"
              class="border"
              no-results-text="No se encontraron datos"
              no-data-text="No se encontraron datos"
              :search="search"
              fixed-header
              height="300"
              :hide-default-footer="true"
              disable-pagination
            >
              <template v-slot:top>
                <div class="w-100 d-flex justify-space-between align-center pa-2">
                  <div class="black--text pt-1">ENCUESTAS ASIGNADAS</div>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="mt-0 text-field-search-matriz"
                    background-color="rgb(220, 220, 220, 0.16)"
                    rounded
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-dialog v-model="dialogAsignar" max-width="600px" v-if="survey.canAssign == '1'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                        <v-icon small dark>
                          mdi-text-box-plus-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-dialog v-model="dialogAsignar" max-width="450px">
                      <v-card>
                        <v-card-title class="py-2">
                          ASIGNAR
                          <v-divider class="mx-2" vertical></v-divider>
                          Encuesta: {{ survey.title }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" class="mr-auto pb-0 pt-6">
                              <v-form ref="form" v-model="valid" lazy-validation>
                                <v-autocomplete
                                  v-model="usuarioSeleccionadoAsign"
                                  :items="usuarios"
                                  :rules="[requiredSelect(usuarioSeleccionadoAsign)]"
                                  @input="searchInputUsuario = null"
                                  :search-input.sync="searchInputUsuario"
                                  label="Encargado"
                                  dense
                                  multiple
                                  return-object
                                >
                                  <template v-slot:selection="{ item, index }">
                                    <div v-if="usuarioSeleccionadoAsign.length < usuarios.length" class="d-flex pt-3">
                                      <span v-if="index < 1">{{ item.fullName }}</span>
                                      <span v-if="usuarioSeleccionadoAsign.length > 0 && index < 0">,&nbsp;</span>
                                      <span v-if="index == 1" class="ml-2 grey--text text-caption">
                                        (+{{
                                          `${
                                            usuarioSeleccionadoAsign.length - 1 > 1 ? 'Otros ' : ''
                                          }${usuarioSeleccionadoAsign.length - 1}`
                                        }})
                                      </span>
                                    </div>
                                    <span v-else-if="index == 0">
                                      TODOS
                                    </span>
                                  </template>
                                  <template v-slot:prepend-item>
                                    <v-list-item
                                      ripple
                                      @click="selectAll(usuarioSeleccionadoAsign, usuarios, 'UA')"
                                      @mousedown.prevent
                                    >
                                      <v-list-item-action>
                                        <v-icon color="primary">{{
                                          iconCheckboxAll(usuarioSeleccionadoAsign, usuarios)
                                        }}</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title> TODOS </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                  </template>
                                </v-autocomplete>
                                <v-text-field
                                  v-model="total"
                                  :rules="required"
                                  label="Total"
                                  type="number"
                                ></v-text-field>
                                <v-text-field v-model="description" label="Descripción"></v-text-field
                              ></v-form>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-btn color="error" class="ml-2" text @click="closeAsignar">Cerrar</v-btn>
                          <v-btn text @click="save">Guardar</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-dialog>
                </div>
                <v-divider></v-divider>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="red" text class="ml-2" @click="cerrarDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import FormatoEncuestaService from '../services/FormatoEncuestaService';
import Swal from 'sweetalert2';

export default {
  name: 'AsignacionEncuestas',
  props: {
    survey: {
      type: Object,
      default: null
    },
    dialogTable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: 'DNI', value: 'dni', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Nombre', value: 'fullName', validateStatus: false, width: '400px', class: 'grey lighten-3' },
      { text: 'Cantidad', value: 'counterTotal', validateStatus: false, class: 'grey lighten-3' }
    ],
    encuestasAsignadas: [],
    usuarios: [],
    usuarioSeleccionado: [],
    usuarioSeleccionadoAsign: [],
    search: '',
    dialogAsignar: false,
    trabajadorSeleccionado: null,
    total: null,
    description: null,
    searchInputUsuario: null,
    required: [(v) => !!v || 'El campo es obligatorio'],
    valid: false,
    showLoading: false
  }),
  computed: {
    selectedAllUsers() {
      return this.usuarioSeleccionado.length === this.usuarios.length;
    },
    selectedSomeUser() {
      return this.usuarioSeleccionado.length > 0 && !this.selectedAllUsers;
    },
    icon() {
      console.log('this.usuarioSeleccionado: ', this.usuarioSeleccionado);
      console.log('this.usuarios: ', this.usuarios);
      if (this.selectedAllUsers) return 'mdi-close-box';
      if (this.selectedSomeUser > 0) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    usuarioSeleccionado() {
      this.cambioUsuario();
    },
    /*survey() {
      this.cambioUsuario();
    },*/
    async dialogTable(val) {
      if (val) {
        await this.clear();
        await this.cambioUsuario();
        await this.initialize();
      }
    },
    async dialogAsignar() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.usuarioSeleccionadoAsign = this.usuarioSeleccionado;
    }
  },
  methods: {
    async clear() {
      if (this.$refs.form) await this.$refs.form.reset();
      this.usuarioSeleccionado = [];
      this.usuarioSeleccionadoAsign = [];
    },
    requiredSelect(items) {
      return () => items.length > 0 || 'El campo es obligatorio';
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectedAllUsers) {
          this.usuarioSeleccionado = [];
        } else {
          this.usuarioSeleccionado = this.usuarios.slice();
        }
      });
    },
    cerrarDialog() {
      this.$emit('cerrarDialog');
    },
    asignarCantidad(item) {
      this.trabajadorSeleccionado = item;
      this.dialog = true;
    },
    async save() {
      if (this.$refs.form.validate(true)) {
        const { isConfirmed } = await this.alertDialogYesNo('question', '¿Desea asignar la encuesta?');

        if (isConfirmed) {
          this.showLoading = true;
          let usuariosSeleccionados = this.usuarioSeleccionadoAsign.map((usuario) => {
            return usuario.userId;
          });
          let usersId = usuariosSeleccionados.join(',');
          const res = await this.formatoEncuestaService.post().execResource('guardarAsignacionEncuestas', {
            assignedTo: usersId,
            quizId: this.survey.id,
            total: this.total,
            description: this.description
          });
          if (res.status) {
            if (res.status) {
              await this.alertDialog('success', res.data);
              this.closeAsignar();
            } else {
              await this.alertDialog('error', res.data);
              this.closeAsignar();
            }
          }
        }
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Guardar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async initialize() {
      this.showLoading = true;
      this.usuarioSeleccionado = [];
      this.usuarios = [];
      const res = await this.formatoEncuestaService.get().execResource('listaUsuarios', { quiz_id: this.survey.id });
      if (res.status) {
        this.usuarios = res.data;
        this.usuarios = this.usuarios.map((usuario) => {
          usuario = { ...usuario, text: usuario.dni + ' - ' + usuario.fullName };
          return usuario;
        });
        this.usuarioSeleccionado = this.usuarios;
      }
      this.showLoading = false;
    },
    async cambioUsuario() {
      if (this.usuarioSeleccionado.length > 0) {
        this.showLoading = true;
        let usuariosSeleccionados = this.usuarioSeleccionado.map((usuario) => {
          return usuario.userId;
        });
        let usersId = usuariosSeleccionados.join(',');
        const res = await this.formatoEncuestaService.get().execResource('listaUsuariosAsignados', {
          assigned_to: usersId,
          quiz_id: this.survey.id
        });
        if (res.status) {
          this.encuestasAsignadas = res.data;
          this.encuestasAsignadas = this.encuestasAsignadas.map((encuesta) => {
            encuesta = { ...encuesta, counterTotal: encuesta.counter + ' / ' + encuesta.total };
            return encuesta;
          });
        }
        this.showLoading = false;
      } else {
        this.encuestasAsignadas = [];
      }
    },
    closeAsignar() {
      this.dialogAsignar = false;
      this.total = '';
      this.description = '';
      this.cambioUsuario();
    },
    checkAll(selected, items) {
      return (selected?.length ?? 0) === items.length;
    },
    checkSome(selected, items) {
      return (selected?.length ?? 0) > 0 && !this.checkAll(selected, items);
    },
    iconCheckboxAll(selected, items) {
      if (this.checkAll(selected, items)) return 'mdi-close-box';
      if (this.checkSome(selected, items)) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectAll(selected, items, type) {
      this.$nextTick(() => {
        if (this.checkAll(selected, items)) {
          if (type == 'U') this.usuarioSeleccionado = [];
          else if (type == 'UA') this.usuarioSeleccionadoAsign = [];
        } else {
          if (type == 'U') this.usuarioSeleccionado = this.usuarios;
          else if (type == 'UA') this.usuarioSeleccionadoAsign = this.usuarios;
        }
      });
    }
  },
  created() {
    this.formatoEncuestaService = this.$httpService(new FormatoEncuestaService(), this);
    //this.initialize();
  }
};
</script>

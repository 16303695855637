<template>
  <v-dialog v-model="dialog" @click:outside="cerrarDialog" scrollable max-width="1500px">
    <v-card v-if="survey !== null">
      <v-card-title>
        <h4>REPORTE DE RESPUESTAS</h4>
        <v-divider class="mx-2" vertical></v-divider>
        Encuesta: {{ survey.title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-4">
        <v-row>
          <v-col cols="12" md="2" sm="4" class="py-0">
            <v-switch v-model="onlyToday" @change="initialize" label="Solo Hoy" color="primary" hide-details></v-switch>
          </v-col>
          <v-col cols="12" md="3" sm="4" class="py-0">
            <v-text-field
              label="Fecha Inicio"
              type="date"
              :disabled="onlyToday"
              @change="initialize"
              required
              v-model="startDate"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="4" class="py-0">
            <v-text-field
              label="Fecha Fin"
              type="date"
              :disabled="onlyToday"
              @change="initialize"
              required
              v-model="endDate"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="4" class="py-0">
            <v-select
              label="Personal"
              @change="initialize"
              v-model="optionPersonal"
              :items="itemsOptionsPersonal"
            ></v-select>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="body"
          :height="400"
          dense
          no-results-text="No se encontraron datos"
          no-data-text="No se encontraron datos"
          :search="search"
          class="border"
          :hide-default-footer="true"
          disable-pagination
          :fixed-header="true"
        >
          <template v-slot:top>
            <div class="w-100 d-flex justify-space-between align-center pa-2">
              <div class="black--text pt-1">RESPUESTAS DE ENCUESTA</div>
              <v-divider class="mx-4" vertical></v-divider>
              <v-text-field
                v-model="search"
                dense
                append-icon="mdi-magnify"
                label="Buscar"
                class="mt-0 text-field-search-matriz"
                background-color="rgb(220, 220, 220, 0.16)"
                rounded
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" vertical></v-divider>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green darken-4" fab dark x-small @click="exportReponses" v-bind="attrs" v-on="on">
                    <v-icon dark>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </template>
                <span>Exportar</span>
              </v-tooltip>
            </div>
            <v-divider></v-divider>
          </template>
          <template v-slot:header="{}">
            <thead>
              <tr>
                <th
                  v-for="(cols, key) in headerSections"
                  :key="key"
                  :colspan="cols"
                  class="text-center blue-grey lighten-3 sortable"
                  style="border-right: 1px solid rgb(255 255 255) !important"
                >
                  {{ key }}
                </th>
              </tr>
              <!--<tr>
                <th v-for="header in props.headers" :key="header.value">
                  {{ header.section }}
                </th>
              </tr>-->
            </thead>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="cerrarDialog">Cerrar</v-btn>
      </v-card-actions>
    </v-card>

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import FormatoEncuestaService from '../services/FormatoEncuestaService';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  name: 'ReporteRespuestas',
  components: {},
  computed: {
    surveyFormat() {
      return this.$store.state.surveyFormat;
    },
    selection() {
      return this.$store.state.selection;
    },
    sectionsActives() {
      if (this.$store.state.surveyFormat.sections)
        return this.$store.state.surveyFormat.sections.filter((element) => element.status === true);
      else return [];
    }
  },
  props: {
    survey: {
      type: Object,
      default: null
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    typesQuestion: [],
    formatoEncuestaService: null,
    showLoading: false,
    valid: false,
    edit: false,
    mostrarFormato: false,
    isView: null,
    search: '',
    headers: [],
    headerSections: {},
    body: [],
    startDate: null,
    endDate: null,
    optionPersonal: '',
    itemsOptionsPersonal: [
      { value: '', text: 'Todos' },
      { value: 'RE', text: 'Solo Registrados' },
      { value: 'NR', text: 'No Registrados' }
    ],
    onlyToday: true
  }),
  watch: {
    async dialog(val) {
      if (val) {
        this.startDate = this.survey.startDate;
        this.endDate = this.survey.endDate;
        await this.initialize();
      }
    }
  },
  methods: {
    numberInActive(id) {
      let number;
      this.sectionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    },
    async initialize() {
      this.showLoading = true;
      this.headers = [];
      this.body = [];
      this.headerSections = {};
      if (this.survey) {
        let startDate = !this.onlyToday ? this.startDate : moment().format('YYYY-MM-DD');
        let endDate = !this.onlyToday ? this.endDate : moment().format('YYYY-MM-DD');
        const res = await this.formatoEncuestaService.get().execResource('reporteRespuestasEncuesta', {
          quiz_id: this.survey.id,
          start_date: startDate,
          end_date: endDate,
          type: this.optionPersonal
        });
        if (res.status) {
          if (res.data.length > 0) {
            let splitCode = '<%seccion|pregunta%>';
            let headers = Object.keys(res.data[0]);
            this.headers = headers.map((el) => {
              if (el.includes(splitCode)) {
                let elSplit = el.split(splitCode);
                return {
                  text: elSplit[1],
                  value: el,
                  class: 'grey lighten-3',
                  width: '300px',
                  section: elSplit[0]
                };
              }
              return { text: el, value: el, width: '200px', class: 'grey lighten-3', section: '' };
            });

            this.headers.splice(0, 1);
            this.headers.forEach((el) => {
              this.headerSections[el.section] = (this.headerSections[el.section] ?? 0) + 1;
            });
            this.body = res.data;
          }
          /*let surveyFormat = res.data[0];
          let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
          surveyFormat = { ...surveyFormat, showFirstSection: showFirstSection };
          this.$store.commit('SET_FORMATO_ENCUESTA', surveyFormat);
          this.$store.commit('RESET_SELECTION_ELEMENT_FORMATO');
          this.mostrarFormato = true;*/
        } else {
          await this.alertDialog('error', res.data);
          this.$router.push({ name: 'matriz_formato_encuesta' });
        }
      }
      this.showLoading = false;
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    atras() {
      this.$router.push({ name: 'matriz_formato_encuesta' });
    },
    typeSelected(id) {
      let type = this.typesQuestion.find((el) => el.id === id);
      return type;
    },
    cerrarDialog() {
      this.$emit('cerrarDialog');
    },
    async exportReponses() {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de exportar las respuestas de la encuesta?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.showLoading = true;
        const fecha1 = moment().format('YYYYMMDDHHMMSS');
        const nombreentregable = 'EXPORT-RESPSUESTAS-ENCUESTA' + fecha1 + '.xlsx';
        const token = localStorage.getItem('led17h9p2x');
        let startDate = !this.onlyToday ? this.startDate : moment().format('YYYY-MM-DD');
        let endDate = !this.onlyToday ? this.endDate : moment().format('YYYY-MM-DD');
        await this.formatoEncuestaService.downloadResource(
          'exportarRespuestasEncuesta',
          {
            token: token,
            quiz_id: this.survey.id,
            start_date: startDate,
            end_date: endDate,
            type: this.optionPersonal
          },
          nombreentregable
        );
        this.showLoading = false;
      }
    }
  },
  created() {
    this.formatoEncuestaService = this.$httpService(new FormatoEncuestaService(), this);
    this.initialize();
  }
};
</script>

<template>
  <div>
    <BaseBreadcrumb :title="'Matriz Formato Encuesta'">
      <!--<template v-slot:options-right>
        <v-btn color="primary" small v-on:click="nuevo">Nuevo</v-btn>
      </template>-->
    </BaseBreadcrumb>
    <div style="padding-top: 55px;">
      <v-card class="elevation-4 px-5 py-5">
        <v-row>
          <v-col cols="12" sm="12">
            <v-data-table
              :headers="headers"
              :items="body"
              :height="430"
              dense
              no-results-text="No se encontraron datos"
              no-data-text="No se encontraron datos"
              :search="search"
              class="border"
              :hide-default-footer="true"
              disable-pagination
              :fixed-header="true"
            >
              <template v-slot:top>
                <div class="w-100 d-flex justify-space-between align-center pa-2">
                  <div class="black--text pt-1">FORMATOS DE ENCUESTA</div>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    dense
                    append-icon="mdi-magnify"
                    label="Buscar"
                    class="mt-0 text-field-search-matriz"
                    background-color="rgb(220, 220, 220, 0.16)"
                    rounded
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" fab dark x-small @click="nuevo" v-bind="attrs" v-on="on">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Nuevo</span>
                  </v-tooltip>
                </div>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.isGlobal`]="{ item }">
                {{ item.isGlobal == 0 ? 'Por Empresa' : 'Por Coorporación' }}
              </template>
              <template v-slot:[`item.accion`]="{ item }">
                <div class="d-flex justify-start pa-0">
                  <v-tooltip bottom v-if="item.canEdit == '1'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon x-small color="primary" v-bind="attrs" v-on="on" @click="editItem(item)">
                        <v-icon small dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.canEdit == '1'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon x-small color="red" v-bind="attrs" v-on="on" v-on:click="deleteItem(item)">
                        <v-icon small dark>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab icon x-small color="success" v-bind="attrs" v-on="on" @click="viewItem(item)">
                        <v-icon small dark>
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Encuesta</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        x-small
                        icon
                        color="blue-grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="duplicateItem(item)"
                      >
                        <v-icon small dark>
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        fab
                        x-small
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="orange darken-3"
                        v-on:click="assignQuiz(item)"
                      >
                        <v-icon small dark>
                          mdi-account-details
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Asignar Encuesta</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab x-small icon v-bind="attrs" v-on="on" color="green darken-3" @click="viewReport(item)">
                        <v-icon small>
                          mdi-file-table-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Reporte de Respuestas</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <asignacion-encuestas
          :dialogTable="dialog"
          :survey="itemSeleccionado"
          @cerrarDialog="dialog = false"
        ></asignacion-encuestas>
        <ver-formato-encuesta
          :dialog="dialogVer"
          :survey="itemSeleccionado"
          @cerrarDialog="dialogVer = false"
        ></ver-formato-encuesta>
        <reporte-respuestas
          :dialog="dialogReporte"
          :survey="itemSeleccionado"
          @cerrarDialog="dialogReporte = false"
        ></reporte-respuestas>
      </v-card>

      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import FormatoEncuestaService from '../services/FormatoEncuestaService';
import AsignacionEncuestas from '../components/AsignacionEncuestas.vue';
import VerFormatoEncuesta from '../components/VerFormatoEncuesta.vue';
import ReporteRespuestas from '../components/ReporteRespuestas.vue';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  components: { AsignacionEncuestas, VerFormatoEncuesta, ReporteRespuestas },
  name: 'MatrizFormatoEncuesta',
  data: () => ({
    headers: [
      { text: 'ACCIONES', value: 'accion', align: 'center', class: 'grey lighten-3' },
      { text: 'Titulo', value: 'title', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Campaña', value: 'campaign', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Alcance', value: 'isGlobal', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Fecha Inicio', value: 'startDate', validateStatus: false, class: 'grey lighten-3' },
      { text: 'Fecha Fin', value: 'endDate', validateStatus: false, class: 'grey lighten-3' }
    ],
    body: [],
    formatoEncuestaService: null,
    itemSeleccionado: null,
    dialog: false,
    showLoading: false,
    dialogVer: false,
    dialogReporte: false,
    search: ''
  }),
  methods: {
    async initialize() {
      this.showLoading = true;
      const res = await this.formatoEncuestaService.get().execResource('listarFormatosEncuesta');
      if (res.status) {
        this.body = res.data.map((el) => {
          return {
            ...el,
            startDate: moment(el.startDate).format('YYYY-MM-DD'),
            endDate: moment(el.endDate).format('YYYY-MM-DD')
          };
        });
      }
      this.showLoading = false;
    },
    nuevo() {
      this.$router.push({ name: 'formato_encuesta' });
    },
    editItem(item) {
      this.$router.push({ path: `formato_encuesta/${item.id}` });
    },
    viewItem(item) {
      this.dialogVer = true;
      this.itemSeleccionado = item;
      //this.$router.push({ path: `formato_encuesta/${item.id}`, query: { isView: true } });
    },
    viewReport(item) {
      this.dialogReporte = true;
      this.itemSeleccionado = item;
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.alertDialogYesNo('question', '¿Desea eliminar el formato?');

      if (isConfirmed) {
        const res = await this.formatoEncuestaService.delete().execResource('borrarFormatoEncuesta', {
          quiz_id: item.id
        });

        if (res.status) {
          await this.alertDialog('success', res.data);
          this.initialize();
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Aceptar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    duplicateItem(item) {
      //localStorage.setItem('idQuizDuplicate', item.id);
      //this.$router.push({ name: 'formato_encuesta' });
      this.$router.push({ path: `formato_encuesta`, query: { idDuplicate: item.id } });
    },
    assignQuiz(item) {
      this.dialog = true;
      this.itemSeleccionado = item;
    }
  },
  created() {
    this.formatoEncuestaService = this.$httpService(new FormatoEncuestaService(), this);
    this.initialize();
  }
};
</script>

<style>
/*.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  color: white !important;
}*/
</style>
